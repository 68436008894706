const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  // DOMAIN SERVER MỚI
  development: {
    api: "https://thanhthuy-api.cgis.asia/",
    media_url: "https://thanhthuy-api.cgis.asia/",
    domainAdminSide: "http://localhost:3000",
    domainUserSide: "http://localhost:3000",
    domainName: "",
    workSpace: "thanhthuy",
    wmsBaseLink: "https://geo.cgis.asia/geoserver/thanhthuy/wms/",
    datastore: 'thanhthuy',
    gwcUrl: 'https://geo.cgis.asia/geoserver/gwc/service/wms/'
    // wmsBaseLink: "https://geoserver.cgis.asia/geoserver/gwc/service/wms/",
  },
    production: {
      api: "https://thanhthuy-api.cgis.asia/",
      media_url: "https://thanhthuy-api.cgis.asia/",
      domainAdminSide: "https://admin.thanhthuy.cgis.asia",
      domainUserSide: "https://thanhthuy.cgis.asia",
      domainName: "cgis.asia",
      workSpace: "thanhthuy",
      wmsBaseLink: "https://geo.cgis.asia/geoserver/gwc/service/wms/",
      datastore: 'thanhthuy',
      gwcUrl: 'https://geo.cgis.asia/geoserver/gwc/service/wms/'
      // wmsBaseLink: "https://geo.cgis.asia/geoserver/gwc/service/wms/",
  }

//   //DOMAIN SERVER CŨ
//   development: {
//     api: "https://phutho-api.cgis.asia/",
//     paht_api: "https://pahtsonla-api.cgis.asia/",
//     media_url: "https://phutho-api.cgis.asia/",
//     domainAdminSide: "http://localhost:3000",
//     domainUserSide: "http://localhost:3000",
//     domainName: "",
//     workSpace: "phutho",
//     wmsBaseLink: "https://geo-phutho.cgis.asia/geoserver/phutho/wms/",
//     datastore: 'phutho',
//     // wmsBaseLink: "https://geo-phutho.cgis.asia/geoserver/gwc/service/wms/",
//   },
//   production: {
//   api: "https://phutho-api.cgis.asia/",
//   paht_api: "https://pahtsonla-api.cgis.asia/",
//   media_url: "https://phutho-api.cgis.asia/",
//   domainAdminSide: "https://admin.phutho.cgis.asia",
//   domainUserSide: "https://phutho.cgis.asia",
//   domainName: "cgis.asia",
//   workSpace: "phutho",
//   wmsBaseLink: "https://geo-phutho.cgis.asia/geoserver/phutho/wms/",
//   datastore: 'phutho',
//   // wmsBaseLink: "https://geo-phutho.cgis.asia/geoserver/gwc/service/wms/",
//   }
};

module.exports = apiEnvironment[env];
